<template>
  <div>
    <a-row :gutter="24" type="flex" align="stretch">
      <a-col :span="24" :lg="24" class="mb-24">
        <a-button @click="visible = true">添加</a-button>
      </a-col>
    </a-row>
    <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
      <a-row :gutter="24" type="flex">
        <a-col :span="24" class="mb-24">
          <Table :data="table1Data" :columns="table1Columns" :innerColumns="innerColumns" :loading="loading"></Table>
        </a-col>
      </a-row>
    </a-card>
    <a-modal v-model:visible="visible" title="添加回复" okText="确认" cancelText="取消" :maskClosable="false" :afterClose="afterClose" @ok="handleOk" :confirm-loading="confirmLoading">
			<a-row :gutter="24" type="flex">
				<a-col :span="24" :lg="4" class="mb-12">
					<div style="line-height: 40px;font-weight: 500;">关键词:</div>
				</a-col>
				<a-col :span="20" :lg="20" class="mb-12">
					<a-input v-model:value="keyword" placeholder="请输入关键词" autocomplete="off" />
				</a-col>
			</a-row>
      <div v-for="(item, index) in list" :key="index">
        <a-row :gutter="24" type="flex">
          <a-col :span="24" :lg="4" class="mb-12">
            <div style="line-height: 40px;font-weight: 500;">类型:</div>
          </a-col>
          <a-col :span="20" :lg="20" class="mb-12">
            <a-select style="width: 120px" @change="handleChange($event, index)">
              <a-select-option value="image">
                图片
              </a-select-option>
              <a-select-option value="video">
                视频
              </a-select-option>
              <a-select-option value="link">
                文章
              </a-select-option>
              <a-select-option value="min">
                小程序
              </a-select-option>
              <a-select-option value="text">
                文字
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <a-row v-if="(item.type && item.type == 'image') || (item.type && item.type == 'video')" :gutter="24" type="flex">
          <a-col :span="24" :lg="4" class="mb-12">
            <div style="line-height: 40px;font-weight: 500;">链接:</div>
          </a-col>
          <a-col :span="20" :lg="20" class="mb-12">
            <a-input v-model="item.url" placeholder="请输入链接" autocomplete="off" />
          </a-col>
        </a-row>
        <a-row v-if="(item.type && item.type == 'min') || (item.type && item.type == 'text')" :gutter="24" type="flex">
          <a-col :span="24" :lg="4" class="mb-12">
            <div style="line-height: 40px;font-weight: 500;">{{item.type == 'min' ? 'openId:' : '回复文字:'}}</div>
          </a-col>
          <a-col :span="20" :lg="20" class="mb-12">
            <a-input v-model="item.text" placeholder="请输入内容" autocomplete="off" />
          </a-col>
        </a-row>
        <div v-if="item.type && item.type == 'link'">
          <a-row :gutter="24" type="flex">
            <a-col :span="24" :lg="4" class="mb-12">
              <div style="line-height: 40px;font-weight: 500;">封面链接:</div>
            </a-col>
            <a-col :span="20" :lg="20" class="mb-12">
              <a-input v-model="item.url" placeholder="请输入链接" autocomplete="off" />
            </a-col>
          </a-row>
          <a-row :gutter="24" type="flex">
            <a-col :span="24" :lg="4" class="mb-12">
              <div style="line-height: 40px;font-weight: 500;">文章链接:</div>
            </a-col>
            <a-col :span="20" :lg="20" class="mb-12">
              <a-input v-model="item.link" placeholder="请输入文章链接" autocomplete="off" />
            </a-col>
          </a-row>
          <a-row :gutter="24" type="flex">
            <a-col :span="24" :lg="4" class="mb-12">
              <div style="line-height: 40px;font-weight: 500;">文章标题:</div>
            </a-col>
            <a-col :span="20" :lg="20" class="mb-12">
              <a-input v-model="item.title" placeholder="请输入标题" autocomplete="off" />
            </a-col>
          </a-row>
          <a-row :gutter="24" type="flex">
            <a-col :span="24" :lg="4" class="mb-12">
              <div style="line-height: 40px;font-weight: 500;">文章描述:</div>
            </a-col>
            <a-col :span="20" :lg="20" class="mb-12">
              <a-input v-model="item.desc" placeholder="请输入描述" autocomplete="off" />
            </a-col>
          </a-row>
        </div>
      </div>
      <a-button @click="addItem">添加回复内容</a-button>
    </a-modal>
  </div>
</template>

<script>
import Table from '../components/Cards/Table';
import { getAutoReply, createGigaBotAutoReply } from '../http/api';
const table1Columns = [
  {
    title: '关键词',
    dataIndex: 'keyword',
  }
];

const innerColumns = [{
  title: '类型',
  dataIndex: 'type',
  scopedSlots: { customRender: 'type' }
}, {
  title: '内容',
  dataIndex: 'data',
  scopedSlots: { customRender: 'data' }
}, {
  title: '文章',
  dataIndex: 'link',
  scopedSlots: { customRender: 'link' }
}]


export default ({
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      table1Data: [],
      table1Columns: table1Columns,
      innerColumns: innerColumns,
      confirmLoading: false,
      visible: false,
      keyword: '',
      list: [{}]
    }
  },
  methods: {
    addItem() {
      let item = {}
      let list = this.list
      list.push(item)
      this.list = JSON.parse(JSON.stringify(list))
    },
    handleChange(e, index) {
      console.log(e, index)
      let list = this.list
      list[index].type = e
      this.list = JSON.parse(JSON.stringify(list))
    },
    afterClose() {
			this.visible = false
      this.confirmLoading = false
      this.visible = false
      this.list = []
      this.keyword = ''
		},
    handleOk() {
      console.log(this.list)
      let data = {
        images: [],
        keyword: this.keyword,
        links: [],
        minPrograms: [],
        texts: [],
        videos: []
      }
      let list = this.list
      list.forEach(e => {
        if (e.type == 'image') {
          data.images.push({
            imageUrl: e.url,
            isImage: true,
            order: 1
          })
        } else if (e.type == 'video') {
          data.videos.push({
            videoUrl: e.url,
            isVideo: true,
            order: 1
          })
        } else if (e.type == 'link') {
          data.links.push({
            link: {
              desc: e.desc,
              imageUrl: e.url,
              url: e.link,
              title: e.title
            },
            isLink: true,
            order: 1
          })
        } else if (e.type == 'min') {
          data.minPrograms.push({
            minProgramKey: e.text,
            isMinProgram: true,
            order: 1
          })
        } else if (e.type == 'text') {
          data.texts.push({
            text: e.text,
            isText: true,
            order: 1
          })
        }
      })
      this.confirmLoading = true
      createGigaBotAutoReply({variables: data}).then(res => {
        console.log(res)
        this.afterClose()
        this.getAutoReply()
      }).catch(() => {
        this.confirmLoading = false
      })
      // this.confirmLoading = true
      // setTimeout(() => {
      //   this.confirmLoading = false
      //   this.visible = false
      // }, 2000)
    },
    getAutoReply() {
      this.loading = true
      getAutoReply().then(res => {
        console.log(res)
        if (res.errors) {
          this.$message.error(res.errors[0].message)
          return
        }
        let data = res.data.gigaBot.autoReply
        data.forEach(item => {
          item.innerData = []
          let reply = item.reply
          reply.forEach(e => {
            let innerData = {}
            if (e.image_url) {
              innerData.type = '图片'
              innerData.data = e
            } else if (e.video_url) {
              innerData.type = '视频'
              innerData.data = e
            } else if (e.link) {
              innerData.type = '文章'
              innerData.data = e
            } else if (e.min_program_key) {
              innerData.type = '小程序'
              innerData.data = e
            } else if (e.text) {
              innerData.type = '文字'
              innerData.data = e
            }
            item.innerData.push(innerData)
          })
        })
        this.table1Data = data
        console.log(this.table1Data)
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  },
  mounted() {
    this.getAutoReply()
  },
})

</script>

<style lang="scss">
.mb-12 {
  margin-bottom: 12px;
}
</style>