<template>
  <a-table :columns="columns" :data-source="data" :row-key="record => record.keyword" :pagination="false"
    :loading="loading" @expand="getInnerData">
    <a-table slot="expandedRowRender" slot-scope="record" :columns="innerColumns" :data-source="record.innerData"
      :pagination="false">
      <span slot="type" slot-scope="text, record">
        <p>{{ record.type }}</p>
      </span>
      <span slot="data" slot-scope="text, record">
        <div v-if="record.data.video_url">
          <video style="width:80px;heigth:auto" :src="record.data.video_url" controls></video>
        </div>
        <div v-if="record.data.image_url">
          <img style="width:80px;heigth:auto" :src="record.data.image_url" />
        </div>
        <div v-if="record.data.link">
          <img style="width:80px;heigth:auto" :src="record.data.link.image_url" />
          <p>{{ record.data.link.title }}</p>
          <p>{{ record.data.link.desc }}</p>
        </div>
        <div v-if="record.data.min_program_key">
          <p>{{ record.data.min_program_key }}</p>
        </div>
        <div v-if="record.data.text">
          <p>{{ record.data.text }}</p>
        </div>
      </span>
    </a-table>
  </a-table>
</template>
<script>
export default ({
  props: ['data', 'columns', 'innerColumns', 'loading'],
  data() {
    return {
      inndata: [],
    }
  },
  methods: {
    getInnerData(expanded, record) {
      console.log(expanded, record)
      if (expanded) {
        this.inndata = record.innerData
      }
    },
  },
});
</script>